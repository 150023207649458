const mapOptions = {
  mapData: [],
  seriesData: [],
  centerData: [118.72557544106654, 25.76882433138131],
  regions: [ // 对不同的区块进行着色
    {
      name: '厦门市', // 区块名称
      label: {
        show: false,
        position: 'bottom'
      },
      tooltip: {
        show: false
      },
      itemStyle: {
        normal: {
          areaColor: '#004981',
          borderColor: '#029fd4',
          borderWidth: 2
        }
      }
    }, {
      name: '泉州市', // 区块名称
      label: {
        show: false
      },
      tooltip: {
        show: false
      },
      itemStyle: {
        normal: {
          areaColor: '#004981',
          borderColor: '#029fd4',
          borderWidth: 2
        }
      }
    }, {
      name: '漳州市', // 区块名称
      label: {
        show: false
      },
      tooltip: {
        show: false
      },
      itemStyle: {
        normal: {
          areaColor: '#004981',
          borderColor: '#029fd4',
          borderWidth: 2
        }
      }
    }, {
      name: '莆田市', // 区块名称
      label: {
        show: false
      },
      tooltip: {
        show: false
      },
      itemStyle: {
        normal: {
          areaColor: '#004981',
          borderColor: '#029fd4',
          borderWidth: 2
        }
      }
    }, {
      name: '福州市', // 区块名称
      label: {
        show: false
      },
      tooltip: {
        show: false
      },
      itemStyle: {
        normal: {
          areaColor: '#004981',
          borderColor: '#029fd4',
          borderWidth: 2
        }
      }
    }, {
      name: '宁德市', // 区块名称
      label: {
        show: false
      },
      tooltip: {
        show: false
      },
      itemStyle: {
        normal: {
          areaColor: '#004981',
          borderColor: '#029fd4',
          borderWidth: 2
        }
      }
    }
  ],

  options: function () {
    return {
      backgroundColor: '', // 背景色
      geo: {
        map: 'fujian', // 上面引入的数据名
        show: true,
        roam: true, // 关闭拖拽
        zoom: 1.8181818181818181,
        scaleLimit: { // 所属组件的z分层，z值小的图形会被z值大的图形覆盖
          min: 1, // 最小的缩放值
          max: 100 // 最大的缩放值
        },
        center: this.centerData, // 调整地图位置
        label: { // 地图显示的地点名
          show: true,
          color: '#fff',
          fontSize: 16,
          zlevel: 2
        },
        tooltip: {
          show: false
        },
        itemStyle: {
          areaColor: '#00051c', // 地图区域的颜色(没有数据时会按照这个颜色显示)
          borderColor: '#2862b7', // 地图区域的边框
          borderWidth: 1
        },
        emphasis: { // 高亮的显示设置
          label: {
            color: '#fff'
          },
          tooltip: {
            show: false
          },
          itemStyle: {
            areaColor: '#37a2da'
          }
        },
        // select: { // 选中显示设置
        //   itemStyle: {
        //     areaColor: '#000001'
        //   }
        // },
        regions: this.regions
      },
      tooltip: {
        show: false,
        trigger: 'item',
        position: 'top',
        // 自定义提示框的内容
        // 这里自定义显示的值是data中value数组的第二个值
        formatter: (params) => {}
      },
      series: this.seriesData
    }
  },

  initData: function (data) {
    const that = this
    this.mapData = data
    const otherData = []
    const xiamenData = []
    const zhangzhouData = []
    const xiamenLineData = []
    for (let index = 0; index < this.mapData.length; index++) {
      const item0 = this.mapData[index]
      const item = {
        name: item0.name,
        code: item0.code,
        value: [item0.longitude, item0.latitude, item0.value],
        count: item0.value,
        total: item0.total,
        index: item0.index
      }
      if (item0.group && item0.group === 'xiamen') {
        xiamenData.push(item)
        const item2 = {
          name: item0.name,
          code: item0.code,
          value: item0.value,
          coords: [
            [item0.longitude, item0.latitude],
            [item0.longitude2end, item0.latitude2end]
          ],
          count: item0.value,
          total: item0.total,
          index: item0.index
        }
        item0.seriesIndex = 3
        item0.dataIndex = xiamenLineData.length
        xiamenLineData.push(item2)
        continue
      }
      if (item0.group && item0.group === 'zhangzhuo') {
        item0.seriesIndex = 1
        item0.dataIndex = zhangzhouData.length
        zhangzhouData.push(item)
        continue
      }
      if ('zhangzhuo,xiamen,'.indexOf(item0.group + ',') === -1) {
        item0.seriesIndex = 0
        item0.dataIndex = otherData.length
        otherData.push(item)
        continue
      }
    }

    this.seriesData = []
    this.seriesData.push({
      name: 'otherData',
      type: 'effectScatter',
      coordinateSystem: 'geo',
      data: otherData,
      symbolSize: 15,
      tooltip: {
        show: true,
        trigger: 'item',
        borderWidth: 0,
        backgroundColor: '#0c4267',
        position: 'top',
        extraCssText: 'opacity:0.9',
        formatter: (params) => {
          return that.formatterTooltip(params)
        }
      },
      encode: {
        value: 2
      },
      showEffectOn: 'render',
      rippleEffect: {
        brushType: 'stroke',
        color: '#0efacc',
        period: 9,
        scale: 5
      },
      hoverAnimation: true,
      label: {
        formatter: '{b}',
        position: 'right',
        fontSize: 14,
        // distance: 100,
        // minMargin: 5,
        // edgeDistance: 10,
        // lineHeight: 15,
        color: '#fff',
        show: true
      },
      emphasis: {
        label: {
          fontSize: 18
        }
      },
      itemStyle: {
        color: '#0efacc',
        shadowBlur: 2,
        shadowColor: '#333'
      },
      labelStyle: { // 线的样式
        type: 'solid',
        opacity: 1,
        color: '#fff'
      },
      zlevel: 1
    })
    this.seriesData.push({
      name: 'zhangzhouData',
      type: 'effectScatter',
      coordinateSystem: 'geo',
      data: zhangzhouData,
      symbolSize: 15,
      tooltip: {
        show: true,
        trigger: 'item',
        borderWidth: 0,
        backgroundColor: '#0c4267',
        extraCssText: 'opacity:0.9',
        position: 'top',
        formatter: (params) => {
          return that.formatterTooltip(params)
        }
      },
      encode: {
        value: 2
      },
      showEffectOn: 'render',
      rippleEffect: {
        brushType: 'stroke',
        color: '#0efacc',
        period: 9,
        scale: 5
      },
      hoverAnimation: true,
      label: {
        formatter: '{b}',
        position: 'left',
        fontSize: 14,
        // distance: 100,
        // minMargin: 5,
        // edgeDistance: 10,
        // lineHeight: 15,
        color: '#fff',
        show: true
      },
      itemStyle: {
        color: '#0efacc',
        shadowBlur: 2,
        shadowColor: '#333'
      },
      emphasis: {
        label: {
          fontSize: 18
        }
      },
      labelStyle: { // 线的样式
        type: 'solid',
        opacity: 1,
        color: '#fff'
      },
      zlevel: 1
    })
    // 绘制散点
    this.seriesData.push({
      name: '厦门区域',
      type: 'scatter',
      coordinateSystem: 'geo',
      data: xiamenData,
      itemStyle: {
        color: '#0efedf'
      },
      symbolSize: 10,
      zlevel: 1
    })
    // 绘引导线
    this.seriesData.push({
      type: 'lines',
      symbol: 'circle',
      symbolSize: [10, 10],
      color: '#fff',
      opacity: 1,
      tooltip: {
        show: true,
        trigger: 'item',
        borderWidth: 0,
        backgroundColor: '#0c4267',
        extraCssText: 'opacity:0.9',
        position: 'top',
        formatter: (params) => {
          return that.formatterTooltip(params)
        }
      },
      label: {
        show: true,
        opacity: 1,
        padding: [10, 6],
        color: '#fff',
        fontSize: 14,
        formatter: '{b}'
      },
      emphasis: {
        label: {
          fontSize: 18
        }
      },
      labelStyle: {
        type: 'solid',
        opacity: 1,
        color: '#fff'
      },
      data: xiamenLineData,
      zlevel: 1
    })
    return this.mapData
  },

  changeZoom: function (zoom) {
    this.options.geo.zoom = zoom
  },
  formatterTooltip: function (params) {
    // console.log('jj', params)
    let str = ' '
    str = `<div><div style="color:#19a6de;font-weight:600">${params.data.name}</div><div style="color:#fff">资产总数: ${params.data.count}件</div><div style="color:#fff">资产价值: ${Number(params.data.total)}元</div></div>`
    return str
  },

  getCenter() {
    return this.centerData
  }
}

export default mapOptions
